import React from "react"


const Nav = () => {

    return (

        <div class="nav">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="../article">Article</a></li>
            <li><a href="../newsletter">Newsletter</a></li>
        </ul>
    </div>

    )
    

}

export default Nav